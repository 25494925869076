import React from "react";
import { Helmet } from "react-helmet";

// Assets

const Index = () => {
	return (
		<>
			<Helmet>
				<title>Bespoke GB | Homepage</title>
				<meta property="og:title" content="Bespoke GB | Homepage" />
			</Helmet>

			<div className="banner home-banner each-slide">
				<video autoPlay muted loop className="videoInsert">
					<source src="hero/4.mp4" type="video/mp4" />
				</video>
				<div className="container h-100">
					<div className="row justify-content-center h-100">
						<div className="col-12 col-md-7 col-lg-7 h-100 d-flex justify-content-center">
							<div className="align-self-center">
								<h1 className="text-center">Bespoke GB</h1>
								<h4
									className="text-center white"
									style={{ fontSize: "14px" }}
								>
									Building Great Businesses
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="welcomeText">
				<div className="container pb-5">
					<div className="mainContentWrapper">
						<h2>Who are we?</h2>
						<p>
							BGB are a multifaceted, specialist construction
							company. We are based in Cambridgeshire and were
							established in 2008, with our main focus serving the
							Automotive Industry. Our skilled, knowledgeable
							workforce have a wealth of experience in the
							Construction Sector and are proficient in all types
							of projects ranging from constructing MOT Bays
							through to building brand new Vehicle Workshops.
						</p>
						<p>
							We offer our knowledge and expertise to all major
							garage equipment manufacturers, suppliers and end
							users direct, in order to enable the installation of
							all of your equipment requirements. Our aim is to
							deliver a seamless, professional service nationwide,
							ensuring 100% satisfaction from our clients.
						</p>

						<h2>What We Do</h2>
						<p>
							BGB are industry leaders for the installation of all
							classes of DVSA approved ATL and ATF facilities. We
							offer a full turnkey design and build package to
							create new steel framed buildings, which includes
							architectural and structural engineering services
							along with Local Authority planning, Building
							Control applications and all necessary approvals.
							All of our contracts are project managed from
							inception to completion by our in-house experienced
							management team, thus minimising downtime and
							turning your project around with minimum disruption
							or delay. BGB are suppliers and installers of all
							types of Prefabricated Steel Vehicle Inspection
							Pits. We have dedicated and experienced Civil
							Engineering teams to carry out floor slab
							alterations, to enable all in-ground garage equipment
							to be recessed into existing floor slabs. We can
							supply a vast range of garage equipment and test
							lanes direct from the manufacturer at competitive
							rates. We can also offer all the standard workshop
							fit out services, ranging from electrical
							installations, compressed airlines, vehicle exhaust
							extraction, oil storage and distribution lines, all
							types of heating, along with, garage equipment
							calibration and maintenance services to name but a
							few. We also offer a full in-house concrete floor
							preparation and epoxy floor coating service,
							including the installation of all appropriate DVSA
							demarcation and hazard detailing.
						</p>
						<p>
							Regardless of whether you need a Class IV Brake
							Tester installing or a new commercial workshop
							designed and built to your own specification, do not
							hesitate to contact us.
						</p>

						<br />

						<h3 className="bgb-pink">
							Bespoke GB are the industry leaders setting the
							standard for others to follow.
						</h3>
					</div>
				</div>
			</div>

			<div
				style={{ backgroundColor: "#555" }}
				className="pb-1 text-center"
			>
				<br />
				<div className="row" style={{ marginRight: "0px" }}>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"I can thoroughly recommend Bespoke GB to carry out
							any of your civil engineering requirements"
						</h4>
						<br />
						<h4 className="bgb-pink">
							Keith McLaren - Retired Managing Director, BradMac
						</h4>
					</div>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"The quality of consultation, planning, workmanship
							and safety is very high indeed"
						</h4>
						<br />
						<h4 className="bgb-pink">
							John Harrop - GES, AutoQuip (GB) Garage Equipment
							Ltd.
						</h4>
					</div>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"I want to thank you and your team very much for
							refinishing my workshop floor. Many many thanks for
							your fantastic customer service."
						</h4>
						<h4 className="bgb-pink">
							Bob Sales - Mereworth Motors Limited
						</h4>
					</div>
				</div>
			</div>
			<div
				style={{ backgroundColor: "#666" }}
				className="pb-1 text-center"
			>
				<br />
				<div className="row" style={{ marginRight: "0px" }}>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"Bespoke GB's professionalism throught this project
							was second to none, I would highly recommend them
							for any such installation, we look forward to
							working together on our next ventures"
						</h4>
						<br />
						<h4 className="bgb-pink">
							Neil Ebbs - Managing Director, MAHA UK Ltd
						</h4>
					</div>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"We partner with Bespoke GB on many of our Garage
							Equipment Installs, Andrew and his teams knowledge
							and high standards are second to none, ensuring a
							smooth hand over from builder to equipment
							installer, hence top quality customer satisfaction."
						</h4>
						<h4 className="bgb-pink">
							Andy Marriott - Trade Garage Equipment
						</h4>
					</div>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"Andrew and the team at Bespoke GB are pros in their
							field, we have worked alongside them on numerous
							occasions and find the whole team a pleasure to deal
							with from planning to execution of a project. We
							will be using them again 100%!!!!"
						</h4>
						<h4 className="bgb-pink">
							Ben Davies - Managing Director, Autoquip (GB) Garage
							Equipment Ltd
						</h4>
					</div>
				</div>
			</div>
			<div
				style={{ backgroundColor: "#555" }}
				className="pb-1 text-center"
			>
				<br />
				<div className="row" style={{ marginRight: "0px" }}>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"I have used Bespoke for several installs of
							Totalkare brake testing equipment including some
							specialist projects and have found the service and
							quality of work to be outstanding. The quality of
							the work and response in quoting and undertaking the
							work has been very good and the Bespoke team have
							been very professional at all of Totalkare
							commercial brake tester installs."
						</h4>
						<h4 className="bgb-pink">
							Adam Bowser - Totalkare
						</h4>
					</div>
					<div class="col-sm pl-5 pr-5">
						<h4 className="white">
							"Bespoke GB Ltd were recommended to us as a company
							who could deliver a high standard of workmanship for
							the groundworks for our new MOT station. The
							tolerances required were very critical for the
							equipment being installed and they did not
							disappoint. A very polite, professional and
							accommodating company, whose work I can only say has
							been nothing but top notch. Very highly
							recommended."
						</h4>
						<h4 className="bgb-pink">
							Adrian Wagstaff - Director, Tyre & Tech Lincoln Ltd
						</h4>
					</div>
				</div>
			</div>
		</>
	);
};

export default Index;
