/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Menu, Dropdown,
} from 'antd';
import {
  MenuOutlined,
  CloseOutlined,
  DownOutlined,
  XOutlined,
  FacebookFilled,
  LinkedinFilled,
  InstagramFilled,
  MailFilled,
  PhoneFilled,
} from '@ant-design/icons';

import projectsJSON from '../../assets/data/projects.json'
import logo from '../../assets/images/logo.png';

const projectsMenu = (
  <Menu>
    <Menu.Item>
      <NavLink exact activeClassName="active" to="/projects">
        All Projects
      </NavLink>
    </Menu.Item>
    {projectsJSON.filter(project => !project.coming_soon).map((project, i) => (
      <Menu.Item>
        <NavLink activeClassName="active" to={`/projects/${project.id}`}>
          {project.name}
        </NavLink>
      </Menu.Item>
    ))}
  </Menu>
);

const Header = (props) => {
  const [toggleNavigation, setToggleNavigation] = useState(false);

  useEffect(() => {
    setToggleNavigation(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocation()]);

  return (
    <>
      <header className={toggleNavigation ? 'mobileToggled' : ''}>
        <Helmet>
          <meta
            name="description"
            content="BGB is a multifaceted, specialist construction company. We are based in Cambridgeshire and were established in 2008, with our main focus serving the Automotive Industry. Our skilled, knowledgeable workforce have a wealth of experience in the Construction Sector and are proficient in all types of projects ranging from constructing MOT Bays through to building brand new Vehicle Workshops."
          />
          <html lang="en" />
        </Helmet>
        <div className="container d-flex justify-content-between h-100">
          <div className="logo align-self-center">
            <NavLink to="/">
              <img loading="lazy" src={logo} alt="Bespoke GB Ltd" />
            </NavLink>
          </div>
          <button
            type="button"
            onClick={() => setToggleNavigation(!toggleNavigation)}
            className="mobileToggleNavigation"
            aria-label="Open Navigation"
          >
            {toggleNavigation ? <CloseOutlined /> : <MenuOutlined />}
          </button>
          <ul
            className={
            `navbar align-self-center d-md-flex ${
              toggleNavigation ? 'd-block' : 'd-none'}`
          }
          >
            <li>
              <NavLink exact activeClassName="active" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/services">
                Services
              </NavLink>
            </li>
            <li className="dropdown">
              <Dropdown trigger={['click']} placement="bottomRight" overlay={projectsMenu}>
                <a
                  href="/#"
                  className="ant-dropdown-NavLink"
                  onClick={(e) => e.preventDefault()}
                >
                  Projects
                  <DownOutlined />
                </a>
              </Dropdown>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/videos">
                Videos
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/contact">
                Contact Us
              </NavLink>
            </li>

            <div className="d-none d-md-block nav_divider" />
    
            <li className="facebookNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/bespokegbltd"
              >
                <FacebookFilled/>
              </a>
            </li>

            <li className="twitterNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://x.com/bespokegbltd"
              >
                <XOutlined/>
              </a>
            </li>
            
            <li className="linkedinNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/bespoke-gb-ltd/"
              >
                <LinkedinFilled/>
              </a>
            </li>

            <li className="instagramNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/bespokegbltd/"
              >
                <InstagramFilled/>
              </a>
            </li>

            <li className="phoneNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel: 01733 570138"
              >
                <PhoneFilled/>
              </a>
            </li>

            <li className="mailNav">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto: info@bespokegb.co.uk"
              >
                <MailFilled/>
              </a>
            </li>
            
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
