/* eslint-disable import/no-named-as-default */
import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Spin } from 'antd';
import './assets/css/layout.scss';
import 'antd/dist/antd.min.css';
import { Switch, Route } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

import Index from './pages/index';
import Services from './pages/services';
import ProjectsIndex from './pages/projects/index'
import Project from './pages/projects/home'

import Contact from './pages/contact'
import Videos from './pages/videos'
import Status from './pages/status';
import FourOFour from './pages/404'
import ScrollToTop from './utils/ScrollToTop';

const maintenance = false;

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 34 }} />);

const App = ({
  location,
}) => {
  return (
    <>
      {location?.pathname !== '/maintenance' && <Header />}
      <main className={localStorage.getItem('bgb_dark') === 'true' ? 'dark-mode' : ''}>{/* <main className="dark-mode"> */}
        <ScrollToTop />
        <Switch>
          <Route exact path="/maintenance" component={Status} />
          {maintenance && <Redirect to="/maintenance" />}

          {/* Non Authenticated Routes */}
          <Route exact path="/" component={Index} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/videos" component={Videos} />
          <Route exact path="/contact" component={Contact} />

          <Route exact path="/projects" component={ProjectsIndex} />
          <Route exact path="/projects/:project" component={Project} />

          <Route exact path="/404" component={FourOFour} />
          <Redirect to="/404" />
        </Switch>
      </main>
      {location?.pathname !== '/maintenance' && <Footer />}
    </>
  );
};

export default withRouter(App);
