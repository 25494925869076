import React from "react";
import { Helmet } from "react-helmet";
import FadeIn from "react-fade-in";
import PageHero from "../components/layout/PageHero.js";
import Service from "../components/layout/Service.js";

import servicesJSON from "../assets/data/services.json";

const Services = () => (
  <>
    <PageHero title="Services" breadcrumb={[{ title: "Services" }]} />
    <Helmet>
      <title>Bespoke GB | Services</title>
      <meta property="og:title" content="Bespoke GB | Services" />
    </Helmet>
    <div className="lightSection text-center">
      <div className="container">
        <h2>Services</h2>
        <FadeIn>
          <div className="row">
            {servicesJSON?.map((feature, i) => (
              <div key={i} className="col-12 col-sm-6 col-lg-4">
                <Service title={feature.title} images={feature.images} caption={feature.caption} />
              </div>
            ))}
          </div>
        </FadeIn>
      </div>
    </div>

    <div style={{backgroundColor: "#666"}} className="pb-1 text-center">
        <br/>
        
        <h1 className="white" style={{marginBottom: "0px", fontSize: "3rem"}}>All the services you need</h1>
        <h1 className="white" style={{marginBottom: "0px", fontSize: "3rem"}}>All in one place</h1>

        <br/>

        <h4 className="white">Bespoke GB - Building Great Businesses across the UK and Ireland</h4>
      </div>
  </>
);

export default Services;
