import React from 'react';
import { Result } from 'antd';
import { createGlobalStyle } from 'styled-components';
import logo from '../assets/images/logo.svg';

const GlobalStyles = createGlobalStyle`
#root {
  display: flex;
  height: 100vh;
  width: 100%;
}
main {
  flex-basis: 100%;
  align-self: center;
}
body {
  background-color: #282828 !important;
  height: 100vh !important;
}
.ant-result-icon {
  margin: 0;
}
.ant-result-icon img {
  width: 200px;
}
.ant-result-title {
  color: #439aa4;
}
.ant-result-subtitle {
  color: #ccc;
}`;

const Status = () => (
  <>
    <GlobalStyles />
    <Result
      icon={<img loading="lazy" src={logo} alt="Bespoke GB" />}
      title="Scheduled Maintenance"
      subTitle="Sorry, we are currently conducting some required maintenance. We'll be back shortly!"
    />
  </>
);

export default Status;
