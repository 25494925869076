import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Result, Button } from "antd";
import PageHero from "../components/layout/PageHero.js";

const FourOFour = () => (
  <>
    <PageHero title="404: Not Found" breadcrumb={[{ title: "404: Not Found" }]} />
    <Helmet>
      <title>Bespoke GB | 404 Not Found</title>
      <meta property="og:title" content="Bespoke GB | 404 Not Found" />
    </Helmet>
    <div className="container white-container">
      <div className="smallContainer mainContentWrapper">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </div>
    </div>
  </>
);

export default FourOFour;
