import React from "react";
import { Helmet } from "react-helmet";
import PageHero from "../../components/layout/PageHero.js";
import ProjectTile from "../../components/layout/ProjectTile.js";

import projectsJSON from "../../assets/data/projects.json";

const Projects = () => (
	<>
		<PageHero title="Projects" breadcrumb={[{ title: "Projects" }]} />
		<Helmet>
			<title>Bespoke GB | Projects</title>
			<meta property="og:title" content="Bespoke GB | Projects" />
		</Helmet>
		<div className="lightSection text-center">
			<div className="container">
				<h2>Projects</h2>
				<div className="row">
					{projectsJSON?.map((project, i) => (
						<div key={i} className="col-12 col-sm-6 col-lg-4">
							<ProjectTile
								id={project.id}
								name={project.name}
								banner={project.banner}
								video={project.video}
								coming_soon={project.coming_soon}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	</>
);

export default Projects;
