import React, { useState, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Carousel, Result, Card, Tabs } from "antd";

import PageHero from "../../components/layout/PageHero";
import projectsJSON from "../../assets/data/projects.json";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowRightOutlined
      className={className}
      style={{ ...style, display: "block", fontSize: "16px", color: "black" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowLeftOutlined
      className={className}
      style={{ ...style, display: "block", fontSize: "16px", color: "black" }}
      onClick={onClick}
    />
  );
};

const settings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Project = (props) => {
  const [projectData, setProjectData] = useState({});

  useLayoutEffect(() => {
    const project = projectsJSON.find((proj) => proj.id === props.match.params.project);
    if (project) {
      setProjectData(project);
    } else {
      setProjectData({ name: "Unknown" });
    }
  }, [props.match.params.project]);

  if (projectData.name === "Unknown") {
    return (
      <>
        <Helmet>
          <title>Bespoke GB | Project not found</title>
        </Helmet>
        <Result
          style={{ paddingTop: 200, marginBottom: 40 }}
          status="404"
          title="This project doesn't exist"
          subTitle="The project you have navigated to doesn't exist."
        />
      </>
    );
  }
  return (
    <>
      <PageHero
        title={`${projectData.name}`}
        breadcrumb={[{ title: "Projects", link: "/projects" }, { title: projectData.name }]}
      />
      <div className={`container white-container pb-5 ${projectData.name}`}>
        <div className="smallContainer mainContentWrapper">
          <h2>{projectData.name}</h2>
          <Card>
            {projectData.tabless_content ? (
              projectData.tabless_content.map((text, i) => <p dangerouslySetInnerHTML={{ __html: text }} />)
            ) : (
              <Tabs defaultActiveKey="1">
                {projectData.tabs?.map((tab, i) => (
                  <Tabs.TabPane tab={tab.name} key={i}>
                    {tab?.description?.map((text, i) => <p dangerouslySetInnerHTML={{ __html: text }} />)}

                    {tab.bullet_points ? (
                      <ul>
                        {tab.content.map((text, i) => (
                          <li dangerouslySetInnerHTML={{ __html: text }} />
                        ))}
                      </ul>
                    ) : (
                      tab.content.map((text, i) => <p dangerouslySetInnerHTML={{ __html: text }} />)
                    )}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            )}
          </Card>

          {projectData.images && (
            <Carousel autoplay autoplaySpeed={3000} style={{ marginTop: "20px" }} arrows {...settings}>
              {projectData.images?.map((image, i) => (
                <div key={i}>
                  <img
                    loading="lazy"
                    src={`/projects/${projectData.id}/images/${image}`}
                    alt={image}
                    style={{ height: "auto", width: "100%", objectFit: "cover" }}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
};

export default Project;
