import React from "react";
import { Helmet } from "react-helmet";
import FadeIn from "react-fade-in";
import PageHero from "../components/layout/PageHero.js";
import Video from "../components/layout/Video.js";

import videosJSON from "../assets/data/videos.json";

const Videos = () => (
  <>
    <PageHero title="Videos" breadcrumb={[{ title: "Videos" }]} />
    <Helmet>
      <title>Bespoke GB | Videos</title>
      <meta property="og:title" content="Bespoke GB | Videos" />
    </Helmet>
    <div className="lightSection text-center">
      <div className="container">
        <h2>Timelapse Footage</h2>
        <FadeIn>
          <div className="row">
            {videosJSON.timelapses?.map((video, i) => (
              <div key={i} className="col-12 col-sm-6 col-lg-4">
                <Video url={video.url} name={video.name} />
              </div>
            ))}
          </div>
        </FadeIn>

        <h2>Drone Footage</h2>
        <FadeIn>
          <div className="row">
            {videosJSON.videos?.map((video, i) => (
              <div key={i} className="col-12 col-sm-6 col-lg-4">
                <Video url={video.url} name={video.name} />
              </div>
            ))}
          </div>
        </FadeIn>
      </div>
    </div>
  </>
);

export default Videos;
