import React from "react";
import { Carousel } from "antd";

function Feature(props) {
  return (
    <div className="card">
      <div className="cardToggler">
        <Carousel autoplay autoplaySpeed={1500}>
          {props.images?.map((image, i) => (
            <div key={i}>
              <img loading="lazy" src={image} alt={props.title} style={{ width: "100%", maxHeight: "185px", objectFit: "cover" }} />
            </div>
          ))}
        </Carousel>

        <h4>{props.title}</h4>
      </div>
      <div className="cardContent">
        <>{props.caption}</>
      </div>
    </div>
  );
}

export default Feature;
