import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../../assets/images/logo.webp';

const Footer = (props) => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3">
          <span className="title">Useful Links</span>
          <ul>
            <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/bespokegbltd">Facebook</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://x.com/bespokegbltd">X</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/bespoke-gb-ltd">Linkedin</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://instagram.com/bespokegbltd">Instagram</a></li>
          </ul>
        </div>
        <div className="col-12 col-md-3">
          <span className="title" />
          <ul>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/projects">Projects</Link></li>
            <li><Link to="/videos">Videos</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
        <div className="col-12 col-md-3"/>
        <div className="col-12 col-md-3 text-align-right">
          <img loading="lazy" src={logo} alt="Bespoke GB Ltd" />
          <span className="copyright">
            &copy; Copyright Bespoke GB Ltd.
            {' '}{new Date().getFullYear()}
          </span>
          <span className="additionalCopyright">VAT Registration No: 932 2278 34</span>
          <span className="additionalCopyright">Registered in England and Wales No: 06483675</span>
          <span className="additionalCopyright">27 Hythegate, Werrington Village, Cambridgeshire, PE4 7ZP</span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
