/* eslint-disable max-len */
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeFilled } from "@ant-design/icons";

function PageHero({ title, hero, breadcrumb, action }) {
  return (
    <>
      <Helmet>
        <title>
          Bespoke GB
          {title && ` | ${title}`}
        </title>
        <meta property="og:title" content={`Bespoke GB | ${title}`} />
      </Helmet>

      <div className="page-banner">
        <div className="container d-block d-md-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <h1>{title}</h1>
            <Breadcrumb className="ml-2 mr-2 d-none d-md-block">
              <Breadcrumb.Item>
                <Link to="/">
                  <HomeFilled />
                </Link>
              </Breadcrumb.Item>
              {breadcrumb &&
                breadcrumb?.map((bc, i) => (
                  <Breadcrumb.Item key={i}>
                    {bc.link ? <Link to={bc.link}>{bc.title}</Link> : <>{bc.title}</>}
                  </Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </div>
          <div className="d-none d-md-flex col-12 col-md-3 align-self-center justify-content-end text-align-right">
            {action}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHero;
