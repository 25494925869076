import { PhoneFilled, MailFilled } from "@ant-design/icons";
import React from "react";
import { Helmet } from "react-helmet";
import PageHero from "../components/layout/PageHero.js";

const Contact = () => (
  <>
    <PageHero title="Contact Us" breadcrumb={[{ title: "Contact Us" }]} />
    <Helmet>
      <title>Bespoke GB | Contact Us</title>
      <meta property="og:title" content="Bespoke GB | Contact Us" />
    </Helmet>
    <div className="lightSection text-center">
      <div className="container">
        <h1>Thank you for visiting</h1>
        <h3>We look forward to hearing from you</h3>
        <a href="tel: 01733 570138">
          <h1>{<PhoneFilled style={{ color: "#e82484" }} />} 01733 570138</h1>
        </a>
        <a href="mailto: info@bespokegb.co.uk">
          <h1>{<MailFilled style={{ color: "#e82484" }} />} info@bespokegb.co.uk</h1>
        </a>
      </div>
    </div>
  </>
);

export default Contact;
