import { Tag, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function ProjectTile(props) {
  return (
    <Link to={`/projects/${props.id}`} className={props.coming_soon && "disabled-link"}>
      <div className="card">
        <div className="cardToggler">
          {props.banner && (
            <img loading="lazy" src={props.banner} alt={props.name} style={{ image: props.banner }}/>
          )}
        </div>
        <div className="cardContent text-center">
          <h4>{props.name}{" "}
          
          {props.video && (
            <Tooltip title="There is a timelapse video of this project. Go to the Videos page to view.">
              <Tag color="green">Video</Tag>
            </Tooltip>
          )} 
          
          {props.coming_soon && (
            <Tooltip title="This project is coming soon.">
              <Tag color="orange">Coming Soon</Tag>
            </Tooltip>
          )}</h4>
        </div>
      </div>
    </Link>
  );
}

export default ProjectTile;
