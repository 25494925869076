import React from "react";
import ReactPlayer from 'react-player/lazy';

function Feature(props) {
  return (
    <div className="card">
      <div className="cardToggler">
        <ReactPlayer url={props.url} controls width="auto" style={{maxHeight: "175px", marginTop: "10px", marginBottom: "10px"}} config={{youtube: { playerVars: {
          rel: 0,
        }}}}/>
      </div>
      <div className="cardContent">
        <h4>{props.name}</h4>
      </div>
    </div>
  );
}

export default Feature;
